<template>
  <div class="playground-page">
    <h1>En construcción...</h1>
    <p>En mi tiempo libre disfruto crear componentes en código, me gusta pensar más allá de Figma cómo puedo hacer un componente real.</p>

    <div class="tabs-container">
      <ul>
        <li v-for="option in options" :key="option" @click="selectOption(option)">
          <button :class="{ active: selected === option }">{{ option }}</button>
        </li>
      </ul>
    </div>

    <div class="grid-container">
      <div v-for="(image, index) in images" :key="index" class="grid-container--column" @click="openModal(image, index)">
        <img class="grid-container--column--img" :src="image.src" :alt="image.title">
      </div>
    </div>

    <Modal
      v-if="isModalOpen"
      :isVisible="isModalOpen"
      :imageSrc="selectedImage"
      :title="selectedTitle"
      :description="selectedDescription"
      @close="closeModal"
    >
      <!-- Renderiza dinámicamente el componente -->
      <component :is="selectedComponent" v-if="selectedComponent" />
    </Modal>
  </div>
</template>

<script>
import Modal from '../components/ModalGallery.vue';
import CardIkea from '../components/CardIkea.vue';
import CardAvatar from '../components/CardAvatar.vue';
import TweenNumber from '../components/TweenNumber.vue';
import GlowButtonEffect from '../components/GlowButtonEffect.vue';
import MusicPlayer from '../components/MusicPlayer.vue';
import CardNotifications from '../components/CardNotification.vue';
import VisibilitySettings from '../components/VisibilitySettings.vue';
import CardSocial from '../components/CardSocial.vue';
import UploadProgress from '../components/UploadProgress.vue';
import OnboardingCard from '../components/OnboardingCard.vue';


export default {
  components: {
    Modal,
    CardIkea,
    CardAvatar,
    TweenNumber,
    GlowButtonEffect,
    MusicPlayer,
    CardNotifications,
    VisibilitySettings,
    CardSocial,
    UploadProgress,
    OnboardingCard
  },
  data() {
    return {
      isOpen: false,
      selected: null,
      isModalOpen: false,
      selectedImage: '',
      selectedComponent: null, // Nuevo estado para el componente seleccionado
      options: ['Componentes', 'Experimentos & Efectos', 'Layouts', 'Páginas'],
      images: [],
      imagesSets: {
        'Componentes': [
          {
            src: require('../assets/exercises/container-thumbnail-card-min.png'),
            title: 'Card',
            description: 'Efecto hover con escala de grises sobre imagen y cambio de color en llamado a la acción.',
            component: 'CardIkea' // Pasa el nombre como string
          },
          {
            src: require('../assets/exercises/container-thumbnail-accordion-min.png'),
            title: 'Accordion',
            description: 'Contenedores collapsables con JS',
            component: 'CardAvatar'
          },
          {
            src: require('../assets/exercises/container-thumbnail-numbers-min.png'),
            title: 'Incremento de números',
            description: 'Efecto CSS y JS.',
            component: 'TweenNumber'
          },
          {
            src: require('../assets/exercises/container-thumbnail-player-min.png'),
            title: 'Player',
            description: 'CSS',
            component: 'MusicPlayer'
          },
          {
            src:  require('../assets/exercises/container-thumbnail-button-min.png'),
            title: 'Efecto brillo sobre botón',
            description: 'CSS',
            component: 'GlowButtonEffect'
          },
          {
            src: require('../assets/exercises/container-thumbnail-profile-actions-min.png'),
            title: 'Card Info Team',
            description: 'CSS',
            component: 'CardNotifications'
          },
          {
            src: require('../assets/exercises/container-thumbnail-visibility-settings-min.png'),
            title: 'Settings Component',
            description: 'CSS',
            component: 'VisibilitySettings'
          },
          {
            src: require('../assets/exercises/container-social-card.png'),
            title: 'Social Card',
            description: 'CSS',
            component: 'CardSocial'
          },
          {
            src: require('../assets/exercises/container-upload.png'),
            title: 'Upload progress',
            description: 'CSS',
            component: 'UploadProgress'
          },
          {
            src: require('../assets/exercises/container-onboarding-card.png'),
            title: 'Onboarding-card',
            description: 'CSS',
            component: 'OnboardingCard'
          },
          
        ],
        'Experimentos & Efectos': [
          {
            src: 'https://images.unsplash.com/photo-1483058712412-4245e9b90334?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8d29ya3NwYWNlfGVufDB8fDB8fHww',
            title: 'Espacio de trabajo minimalista',
            description: 'Un espacio de trabajo limpio y ordenado con un ambiente creativo.'
          },
          {
            src: 'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8d29ya3NwYWNlfGVufDB8fDB8fHww',
            title: 'Diseño de oficina moderna',
            description: 'Una oficina moderna con elementos de diseño únicos.'
          },
          {
            src: 'https://images.unsplash.com/photo-1502945015378-0e284ca1a5be?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d29ya3NwYWNlfGVufDB8fDB8fHww',
            title: 'Oficina creativa',
            description: 'Un entorno de oficina con colores vibrantes y detalles creativos.'
          }
        ],
        'Layouts': [
            {
              src: 'https://plus.unsplash.com/premium_photo-1675435644687-562e8042b9db?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29mZmVlfGVufDB8fDB8fHww',
              title: 'Café en la mañana',
              description: 'Un taza de café en una mesa moderna, con luz natural matutina.'
            },
            {
              src: 'https://plus.unsplash.com/premium_photo-1669374537810-f88d8ad82818?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGNvZmZlZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Taza de café y libro',
              description: 'Una mesa con una taza de café y un libro abierto, ideal para una pausa relajante.'
            },
            {
              src: 'https://images.unsplash.com/photo-1511537190424-bbbab87ac5eb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNvZmZlZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Diseño interior moderno',
              description: 'Un espacio interior moderno con muebles de diseño elegante.'
            }
        ],
        'Páginas': [
            {
              src: 'https://images.unsplash.com/photo-1485965120184-e220f721d03e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmlrZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Bicicleta en la ciudad',
              description: 'Una bicicleta estacionada en una calle tranquila de la ciudad.'
            },
            {
              src: 'https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YmlrZXxlbnwwfHwwfHx8MA%3D%3D',
              title: 'Paseo en bicicleta',
              description: 'Un ciclista disfrutando de un paseo en una zona urbana.'
            },
            {
              src: 'https://images.unsplash.com/photo-1475666675596-cca2035b3d79?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGJpa2V8ZW58MHx8MHx8fDA%3D',
              title: 'Bicicleta vintage',
              description: 'Una bicicleta vintage apoyada en una pared de ladrillo, evocando nostalgia.'
            }
        ]
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selected = option;
      this.images = this.imagesSets[option];
      this.isOpen = false;
    },
    openModal(image) {
      this.selectedImage = image.src;
      this.selectedTitle = image.title;
      this.selectedDescription = image.description;
      this.selectedComponent = image.component; // Asegúrate de que sea 'CardIkea'
      console.log('selectedComponent:', this.selectedComponent); // Log para verificar el valor
      this.isModalOpen = true;
  },
    closeModal() {
      this.isModalOpen = false;
      this.selectedImage = '';
      this.selectedComponent = null;
    }
  },
  created() {
    this.images = this.imagesSets['Componentes'];
  }
}
</script>
  
<style lang="scss" scoped>

  .playground-page {
    margin: 172px 32px;
    text-align: left;

    .grid-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 24px;
      gap: 16px;

      &--column {
        cursor: pointer;
        flex-grow: 1;
        max-width: 340px;

        &--img {
          border-radius: 16px;
          cursor: pointer;
          object-fit: cover;
          height: 100%;
          width: 100%;

          &:hover {
            opacity: 20%;
          }
        }
      }
    }
  }

  h1 {
    color: #42b983;
    text-align: center;
  }

  p {
    color: white;
    text-align: center;
  }

  .tabs-container {
    ul {
      border-radius: 200px;
      display: none;
      gap: 8px;
      list-style: none;
      padding: 0;

      @media only screen and (max-width: 400px) {  
        overflow-x: scroll;
        width: 100%;
      }
      li { 
        button {
          background-color: #202020;
          border-radius: 200px;
          border: 1px solid #7d7d7d;
          color: white;
          cursor: pointer;
          padding: 10px 20px;
          transition: all 0.3s ease-in-out;
          white-space: nowrap;

          &:hover {
            border: 1px solid #41B983;
            img {
              transform: rotate(180deg);
            }
          }

          &.active {
            background-color: #2a2a2a; /* Cambia el color del botón activo */
            border: 1px solid #41B983;
          }
        }

      }
    }
  }
</style>