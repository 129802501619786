<template>
    <section class="social-card">
      <div class="social-card__header">
        <img src="https://mighty.tools/mockmind-api/content/abstract/49.jpg" alt="">
        <div class="social-card__header__info">
          <h5>Alberto Saldarriaga</h5>
          <span>HR Assistant - @alberto_salda_95</span>
        </div>
        <button>Add contact</button>
      </div>
      <div class="social-card__body">

      </div>
      <div class="social-card__footer">

      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'SocialCard'
  };
  </script>
  
<style lang="scss" scoped>
    .social-card {
      background-color: white; 
      border: 1px solid #e4e4e7;
      border-radius: 20px;
      min-width: 300px;
      max-width: 320px;
      margin: 72px auto;
      padding: 24px 16px;
      
      &__header {
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        img {
          border-radius: 50%;
          height: 56px;
          width: 56px;
        }

        &__info {
          text-align: left;
          margin-bottom: 12px;

          h5 {
            margin: 8px 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 16%;
          }

          span {
            font-size: 14px;
            color: dimgray;
            margin: 0;
          }
        }

        button {
          box-shadow: rgba(51, 51, 51, 0.06) 0px 2px 4px, rgb(233, 233, 233) 0px 0px 0px 1px, rgba(61, 61, 61, 0.08) 0px -0.5px 0.5px inset;
          border-radius: 8px;
          border: 0;
          background-color: white;
          cursor: pointer;
          padding: 8px 16px;

          &:hover {
            background-color: rgb(242 242 242/var(--tw-bg-opacity,1));
          }
        }
      }
    }
</style>