<template>
    <div class="main-container">
        <section class="info-team">
        <div class="info-team__header">
            <div class="info-team__header__title">
                <div class="info-team__header__title__icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="remixicon size-5 text-text-sub-600"><path d="M13.3414 4C13.1203 4.62556 13 5.29873 13 6H5V20H19V12C19.7013 12 20.3744 11.8797 21 11.6586V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V5C3 4.44772 3.44772 4 4 4H13.3414ZM19 8C20.1046 8 21 7.10457 21 6C21 4.89543 20.1046 4 19 4C17.8954 4 17 4.89543 17 6C17 7.10457 17.8954 8 19 8ZM19 10C16.7909 10 15 8.20914 15 6C15 3.79086 16.7909 2 19 2C21.2091 2 23 3.79086 23 6C23 8.20914 21.2091 10 19 10Z"></path></svg>
                </div>
                <div class="info-team__header__title__content">
                    <h5>Notificaciones</h5>
                    <span>Escoge por dónde prefieres recibir tus notificaciones.</span>
                </div>
            </div>
        </div>

        <div class="info-team__section">
            <label class="info-team__section__switch">
                <input id="checkbox-1" type="checkbox">
                <span class="slider"></span>
            </label>
            <label for="checkbox-1" class="info-team__section__content">
                <p>Mail</p>
                <span>Recibe novedades y actualizaciones por correo electrónico.</span>
            </label>
        </div>

        <div class="info-team__section">
            <label class="info-team__section__switch">
                <input id="checkbox-2" type="checkbox">
                <span class="slider"></span>
            </label>
            <label for="checkbox-2" class="info-team__section__content">
                <p>Whatsapp</p>
                <span>Recibe mensajes y notificaciones instantáneas por WhatsApp.</span>
            </label>
        </div>

        <div class="info-team__section">
            <label class="info-team__section__switch">
                <input id="checkbox-3" type="checkbox">
                <span class="slider"></span>
            </label>
            <label for="checkbox-3" class="info-team__section__content">
                <p>SMS</p>
                <span> Recibe alertas y recordatorios importantes por SMS.</span>
            </label>
        </div>

        <div class="info-team__footer">
            <button class="info-team__footer__cancel">Cancelar</button>
            <button class="info-team__footer__save">Guardar</button>
        </div>
    </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InfoTeam'
  };
  </script>
  
<style lang="scss" scoped>
 
 .main-container {
    align-items: center;
    display: flex;
    height: 80vh;
    justify-content: center;
    width: 100%;

    .info-team {
        border-radius: 16px;
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.22);
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 32px 32px;
        min-width: 400px;
        max-width: 440px;

        &__header {
            &__title {
                align-items: center;
                display: flex;
                justify-content: start;
                gap: 16px;
                text-align: left;
                padding-bottom: 20px;
                border-bottom: 2px solid #e7ecf3;

                &__icon {
                    align-items: center;
                    border: 1px solid #d7dce2;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    height: 40px;
                    width: 40px;

                    svg {
                        fill: #7b7b7b;
                    }
                }
            }

            h5 {
                font-size: 16px;
                font-weight: 600;
                margin: 0dvh;
            }

            span {
                font-size: 14px;
                color: #7b7b7b;
            }

        }

        &__section {
            display: flex;
            color: #7A829B;
            justify-content: start;
            width: 100%;
            gap: 22px;

            &__switch {
                font-size: 17px;
                position: relative;
                display: inline-block;
                width: 36px;
                height: 20px;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #e2e5e8;
                    border: none;
                    transition: .4s;
                    border-radius: 30px;
                }

                .slider:before {
                    position: absolute;
                    content: "";
                    height: 12px;
                    width: 12px;
                    border-radius: 20px;
                    left: 2px;
                    bottom: 4px;
                    background-color: #fff;
                    transition: .4s;
                }

                input:checked + .slider {
                    background-color: #007bff;
                    border: none;
                }

                input:focus + .slider {
                    box-shadow: 0 0 1px #007bff;
                }

                input:checked + .slider:before {
                    transform: translateX(18px);
                    background-color: #fff;
                }
            }

            &__content {
                cursor: pointer;
                flex: 1;
                text-align: left;

                p {
                    color: #282828;
                    font-weight: 600;
                    font-size: 16px;
                    margin: 0 0 4px 0;
                }

                span {
                    font-size: 14px;
                }
            }

            &__icon {
                display: flex;
                justify-content: center;
                width: 64px;

                svg {
                    cursor: pointer;
                    fill: #4083F7;

                    &:hover {
                        fill: #1257CD;
                    }
                }
            }

        }

        &__footer {
            display: flex;
            padding-top: 24px;
            border-top: 2px solid #e7ecf3;
            justify-content: flex-end;
            gap: 8px;

            button {
                border-radius: 8px;
                cursor: pointer;
                flex: 1;
                font-size: 16px;
                padding: 12px 8px;

                &:hover {
                    opacity: 0.7;
                }
            }

            &__cancel {
                background-color: transparent;
                border: 1px solid #7b7b7b;
                color: #282828;
            }

            &__save {
                background-color: #282828;
                border: none;
                color: white;
            }
        }
    }
 }
    
</style>