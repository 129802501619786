<template>
    <section class="upload-progress">
        <img src="../assets/download-icon.svg" alt="">
        <div class="upload-progress__container">
            <div class="upload-progress__container__info">
                <h5>2024-my-portfolio.pdf</h5>
                <div class="upload-progress__container__info__state">
                    <span class="upload-progress__container__info__state__text">Completed</span>
                </div>
            </div>
            <div class="upload-progress__container__bar" ref="progressBar"></div>
            <span class="upload-progress__container__weight">12.6 MB of 12.6 MB </span>
            <div class="upload-progress__container__actions">
                <button class="upload-progress__container__actions__change" @click="changeProgress" ref="changeButton">Change</button>
                <button class="upload-progress__container__actions__remove">Remove</button>
            </div>
        </div>
    </section>
  </template>

  <script>
  export default {
    name: 'SocialCard',
    data() {
        return {
          progress: 0, // Initial progress
        };
    },
    methods: {
        changeProgress() {
          this.progress = 100;
          this.$refs.progressBar.style.setProperty('--progress-width', this.progress + '%');
          // Optional: Disable the button after it's clicked
          this.$refs.changeButton.disabled = true;

        }
    },
  };
  </script>

  <style lang="scss" scoped>
    .upload-progress {
        display: flex;
        box-shadow: rgb(11 11 11 / 23%) 0px 1px 3px 0px;
        background-color: white;
        border-radius: 16px;
        gap: 16px;
        padding: 16px;
        margin: auto;
        width: 400px;

        img {
            height: 56px;
            width: 56px;
        }

        &__container {
            text-align: left;
            flex: 1;

            &__bar {
                background-color: gainsboro;
                border-radius: 8px;
                height: 8px;
                overflow: hidden;
                position: relative;
                width: 100%;

                &:before {
                    background-color: #2EA557;
                    content: "";
                    height: 100%;
                    left: 0;
                    top: 0;
                    position: absolute;
                    transition: all 6s cubic-bezier(0.82, 0.39, 0.6, 2.08);
                    width: var(--progress-width, 0%); //Initial value
                }
            }
            
            &__info {
                align-items: center;
                display: flex;
                gap: 8px;
                margin-bottom: 8px;

                h5 {
                    flex: 1;
                    font-weight: 400;
                    color: #3d3d3d;
                    margin: 0;
                }

                &__state {
                    
                    span {
                        color: #3D3D3D;
                        font-size: 14px;
                    }
                }
                
            }

            &__weight {
                font-size: 13px;
                color: #8f8f8f;
                margin-top: 20px;
            }

            &__actions {
                margin-top: 8px;
                display: flex;
                gap: 8px;

                &__change, &__remove {
                    border-radius: 8px;
                    border: 0;
                    padding: 8px 12px;
                }

                &__change {
                    background-color: #F2F2F2;
                    color: #707070;
                    cursor: pointer;

                    &:hover {
                        background-color: #ebebeb;
                    }
                }

                &__remove {
                    background-color: #fdeeef;
                    color: #f45c6c;
                    cursor: pointer; 

                    &:hover {
                        background-color: #f33f5426;
                    }
                }
            }
        }
    }
</style>