<template>
    <section class="onboarding-card">
      <img src="https://rb.gy/i0dt5d" alt="">
      <div class="onboarding-card__content">
        <h4>Te damos la bienvenida</h4>
        <p>Echa un vistazo rápido a nuestros productos y explora la mejor experiencia de comida virtual. </p>
      </div>
      <div class="container-footer">
        <ul class="dots">
          <li class="active"></li>
          <li class=""></li>
          <li class=""></li>
          <li class=""></li>
        </ul>
        <div class="container-footer__actions">
          <button class="container-footer__actions__secondary">
            Saltar
          </button>
          <button class="container-footer__actions__primary">
            Siguiente
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right -me-1 opacity-60 transition-transform group-hover:translate-x-0.5" aria-hidden="true"><path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg>
          </button>
        </div>
      </div>
    <!-- https://originui.com/dialog -->
    </section>
  </template>
    
    <script>
    export default {
      name: "OrboardingCard"
    };
    </script>
    
  <style lang="scss" scoped>
      .onboarding-card {
        box-shadow: #312f2f7d 0px 1px 3px;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        height: 392px;
        gap: 12px;
        max-width: 400px;
        margin: 48px auto;

        img {
          border-radius: 12px;
          object-fit: cover;
          height: 240px;
          width: 100%;
        }

        &__content {
          flex: 1;
          text-align: left;

          h4, p {
            padding: 0;
            margin: 0;
          }

          h4 {
            font-size: 18px;
            margin-bottom: 8px;
          }

          p {
            font-size: 14px;
            color: gray;
          }
        }

        .container-footer {
          display: flex;
          height: 40px;

          .dots {
            display: flex;
            flex: 1;
            list-style: none;
            gap: 4px;
            padding: 0px;

            .active {
              background-color: #1b1718;
            }

            li {
              background-color: #9a8a8e;
              border-radius: 50%;
              display: block;
              content: "";
              height: 6px;
              width: 6px;
            }
          }

          &__actions {
            display: flex;
            gap: 8px;

            &__primary {
              align-items: center;
              color: #fff;
              background-color: #1b1718;
            }

            &__secondary {
              border: 1px solid #1b1718;
            }

            &__primary, &__secondary {
              border: none;
              border-radius: 8px;
              display: flex;
              cursor: pointer;
              gap: 12px;
              padding: 12px 16px;

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
  
      }
  
  </style>